
































































































































.builder-content {
  max-width: 100%;
  display: inline-block;
}

.builder-toolbar-wrapper {
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 49px;
  border-bottom: 1px solid #ccc;

  #builder-toolbar {
    width: 860px;
  }
  .builder-toolbar-custom {
    flex: 1 0 auto; 
  }
}

.builder-body {
  margin-top: 100px;
  height: calc(100vh - 100px);
  overflow-y: scroll;
}
